import { typedEntries } from "../../utils/objects";
import {
  EntitlementValueObject as EntitlementValueObjectDto,
  ReclaimEdition as ReclaimEditionDto,
  SubscriptionFrequency as SubscriptionFrequencyDto,
  SubscriptionOption as SubscriptionOptionDto,
  SubscriptionOptions as SubscriptionOptionsDto,
  SubscriptionPrice as SubscriptionPriceDto,
  TeamMembershipSummary as TeamMembershipSummaryDto,
} from "./client";
import {
  EntitlementIntegrations,
  EntitlementSupport,
  EntitlementTable,
  EntitlementTableRow,
  EntitlementType,
  EntitlementValueObject,
  ReclaimEditionStr,
  ReclaimEditionV2,
  SubscriptionFrequencyStr,
  SubscriptionOption,
  SubscriptionOptions,
  SubscriptionPrice,
  TeamMembershipSummary,
} from "./Team.types";

export const dtoToTeamMembershipSummary = (dto: TeamMembershipSummaryDto): TeamMembershipSummary => dto;

export const dtoToSubscriptionFrequencyStr = (dto: SubscriptionFrequencyDto): SubscriptionFrequencyStr =>
  dto as SubscriptionFrequencyStr;
export const dtoToReclaimEditionV2 = (dto: ReclaimEditionDto): ReclaimEditionV2 => {
  switch (dto) {
    case ReclaimEditionDto.LITE:
    case ReclaimEditionDto.STARTER:
    case ReclaimEditionDto.BUSINESS:
    case ReclaimEditionDto.ENTERPRISE:
      return dto as ReclaimEditionV2;
    default:
      throw new Error(`Received non-V2 edition: ${dto}`);
  }
};

export const dtoToReclaimEditionStr = (dto: ReclaimEditionDto): ReclaimEditionStr => dto as ReclaimEditionStr;

export const subscriptionFrequencyStrToDto = (subFrequency: SubscriptionFrequencyStr): SubscriptionFrequencyDto =>
  subFrequency as SubscriptionFrequencyDto;
export const reclaimEditionV2ToDto = (edition: ReclaimEditionV2): ReclaimEditionDto => edition as ReclaimEditionDto;

export const dtoToEntitlementValueObject = <T, HAS_ACTUAL extends boolean = boolean, IS_TOP extends boolean = boolean>(
  dto: EntitlementValueObjectDto
): EntitlementValueObject<T, HAS_ACTUAL, IS_TOP> =>
  ({
    value: dto.value as unknown as T,
    actualValue: dto.value as unknown as HAS_ACTUAL extends true ? T : void,
    ...(((!dto.nextEdition || dto.nextValue === undefined) as IS_TOP)
      ? {
          isTop: true,
          nextEdition: void 0,
          nextValue: void 0,
        }
      : {
          isTop: false,
          nextEdition: dtoToReclaimEditionStr(dto.nextEdition as ReclaimEditionDto),
          nextValue: dto.nextValue as unknown as T,
        }),
  } as unknown as EntitlementValueObject<T, HAS_ACTUAL, IS_TOP>);

export const dtoToNumberEntitlementValueObject = <
  HAS_ACTUAL extends boolean = boolean,
  IS_TOP extends boolean = boolean
>(
  dto: EntitlementValueObjectDto
): EntitlementValueObject<number, HAS_ACTUAL, IS_TOP> =>
  ({
    value: Number(dto.value),
    actualValue: (typeof dto.actualValue === "number"
      ? Number(dto.actualValue)
      : void 0) as unknown as HAS_ACTUAL extends true ? number : void,
    ...(!!dto.nextEdition && dto.nextValue !== undefined
      ? {
          isTop: false,
          nextEdition: dtoToReclaimEditionStr(dto.nextEdition),
          nextValue: Number(dto.nextValue),
        }
      : {
          isTop: true,
        }),
  } as unknown as EntitlementValueObject<number, HAS_ACTUAL, IS_TOP>);

export const dtoToEntitlementTableRow = <HAS_ACTUALS extends boolean = boolean>(
  dto: Record<string, EntitlementValueObjectDto>
): EntitlementTableRow<HAS_ACTUALS> => ({
  MAX_TEAM_SIZE: dtoToNumberEntitlementValueObject<HAS_ACTUALS>(dto.MAX_TEAM_SIZE),
  SCHEDULER_WEEKS: dtoToNumberEntitlementValueObject<HAS_ACTUALS>(dto.SCHEDULER_WEEKS),
  MAX_TASKS: dtoToNumberEntitlementValueObject<HAS_ACTUALS>(dto.MAX_TASKS),
  MAX_CALENDARS: dtoToNumberEntitlementValueObject<HAS_ACTUALS>(dto.MAX_CALENDARS),
  MAX_SYNCS: dtoToNumberEntitlementValueObject<HAS_ACTUALS>(dto.MAX_SYNCS),
  MAX_HABITS: dtoToNumberEntitlementValueObject<HAS_ACTUALS>(dto.MAX_HABITS),
  CUSTOM_BLOCKING: dtoToEntitlementValueObject<boolean, HAS_ACTUALS>(dto.CUSTOM_BLOCKING),
  MAX_SCHEDULING_LINKS: dtoToNumberEntitlementValueObject<HAS_ACTUALS>(dto.MAX_SCHEDULING_LINKS),
  MAX_1_ON_1_ORGANIZE: dtoToNumberEntitlementValueObject<HAS_ACTUALS>(dto.MAX_1_ON_1_ORGANIZE),
  MAX_1_ON_1_ATTEND: dtoToNumberEntitlementValueObject<HAS_ACTUALS>(dto.MAX_1_ON_1_ATTEND),
  INTEGRATIONS: dtoToEntitlementValueObject<EntitlementIntegrations, HAS_ACTUALS>(dto.INTEGRATIONS),
  SUPPORT: dtoToEntitlementValueObject<EntitlementSupport, HAS_ACTUALS>(dto.SUPPORT),
  SSO: dtoToEntitlementValueObject<boolean, HAS_ACTUALS>(dto.SSO),
});

export const dtoToEntitlementTable = <HAS_ACTUALS extends boolean = boolean>(
  dto: Record<string, Record<string, EntitlementValueObjectDto>>
): EntitlementTable<HAS_ACTUALS> =>
  (Object.entries(dto) as [ReclaimEditionV2, Record<EntitlementType, EntitlementValueObjectDto>][]).reduce(
    (table, [edition, dtoRow]) => {
      table[edition] = dtoToEntitlementTableRow<HAS_ACTUALS>(dtoRow);
      return table;
    },
    {} as EntitlementTable<HAS_ACTUALS>
  );

export const dtoToSubscriptionPrice = (dto: SubscriptionPriceDto): SubscriptionPrice => ({ ...dto });

export const dtoToSubscriptionOption = (dto: SubscriptionOptionDto): SubscriptionOption => ({
  ...dto,
  prices:
    dto.prices &&
    typedEntries(dto.prices).reduce((prices, [freq, price]) => {
      prices[freq] = dtoToSubscriptionPrice(price);
      return prices;
    }, {} as SubscriptionOption["prices"]),
});

export const dtoToSubscriptionOptions = (dto: SubscriptionOptionsDto): SubscriptionOptions => ({
  ...dto,
  options: typedEntries(dto.options).reduce((options, [edition, option]) => {
    options[edition] = dtoToSubscriptionOption(option);
    return options;
  }, {} as SubscriptionOptions["options"]),
});
